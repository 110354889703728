import React from 'react';
import { Chip, Typography, Stack } from '@mui/material';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import colors from '../colors.styles';
import { formattedFirstname, getPostStatus } from './videoReview.service';

const colorMap = {
  approved: {
    background: colors.base.light_green,
    title: colors.base.dark_green,
  },
  rejected: {
    background: colors.base.light_red,
    title: colors.base.dark_red,
  },
  expired: {
    background: colors.base.very_light_gray,
    title: colors.base.dark_gray,
  },
};

function getColorMap(status) {
  if (status === 'auto_approved') {
    return 'approved';
  }
  if (status === 'auto_rejected') {
    return 'rejected';
  }
  return status;
}

const StatusHeader = ({ post }) => {
  const { reviewAction } = post;
  const status = getPostStatus(post);
  const formattedStatus = status.startsWith('auto_')
    ? status.replace('_', ' ').toUpperCase()
    : status.toUpperCase();

  return (
    status !== 'pending' && (
      <Stack
        display="flex"
        direction="row"
        alignItems="center"
        spacing={1}
        sx={{ marginBottom: '10px' }}
      >
        <Chip
          label={formattedStatus}
          sx={{
            backgroundColor: colorMap[getColorMap(status)].background,
            color: colorMap[getColorMap(status)].title,
            fontWeight: '600',
          }}
        />
        {status !== 'pending'}
        <Typography sx={{ color: colors.base.dark_gray }}>
          {status === 'expired' && (
            <>on {moment(post.tz_publish_time).format('MMMM Do, YYYY')}</>
          )}

          {status !== 'expired' && reviewAction && (
            <>
              on {moment(reviewAction.reviewed_at).format('MMMM Do, YYYY')}
              {!['expired', 'auto_approved', 'auto_rejected'].includes(
                status
              ) && (
                <>
                  &nbsp;by&nbsp;
                  <span style={{ fontWeight: '600' }}>
                    {formattedFirstname(reviewAction?.reviewed_by) ||
                      'Not found'}
                  </span>
                </>
              )}
            </>
          )}

          {status !== 'expired' && !reviewAction && 'No review data available'}
        </Typography>
      </Stack>
    )
  );
};

export default StatusHeader;

StatusHeader.propTypes = {
  post: PropTypes.shape({
    id: PropTypes.string,
    channels: PropTypes.arrayOf(PropTypes.string),
    store_number: PropTypes.string,
    message: PropTypes.string,
    created_by_firstname: PropTypes.string,
    created_by_lastname: PropTypes.string,
    created_by_user_id: PropTypes.shape({}),
    email: PropTypes.string,
    video_url: PropTypes.string,
    thumbnail: PropTypes.string,
    publish_time: PropTypes.string,
    tz_publish_time: PropTypes.string,
    audio_detected: PropTypes.arrayOf(
      PropTypes.shape({
        isrc: PropTypes.string,
        title: PropTypes.string,
        artist: PropTypes.string,
        label: PropTypes.string,
        distributor: PropTypes.string,
        allowedMusic: PropTypes.bool,
        audioMatchStart: PropTypes.number,
        audioMatchEnd: PropTypes.number,
      })
    ),
    has_user_been_rejected: PropTypes.bool,
    reviewAction: PropTypes.shape({
      reviewed_by_walmart_id: PropTypes.string,
      reviewed_at: PropTypes.string,
      reviewed_by: PropTypes.string,
      action: PropTypes.string,
    }),
  }).isRequired,
};
