import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Box, Container, Grid } from '@mui/material';
import { Route, Routes } from 'react-router-dom';
import DrawerMenu from './DrawerMenu';
import Home from './Home.page';
import Users from '../users/Users.page';
import Stores from '../stores/Stores.page';
import User from '../users/UserDetails.page';
import Priorities from '../priorities/Priorities.page';
import LoginPage from '../auth/Login.page';
import { UserContext } from '../auth/User.context';
import Unauthorized from '../auth/Unauthorized.page';
import SpinningSpark from '../shared/components/SpinningSpark';
import StoreDetails from '../stores/StoreDetails.page';
import POTD from '../potd/POTD.page';
import POTDCreate from '../potd/POTDCreate.page';
import POTDCandidates from '../potd/POTDCandidates.page';
import POTDEdit from '../potd/POTDEdit.page';
import AccessControl from './AccessControl';
import VideoReviewDashboard from '../videoReviewDashboard/videoReviewDashboard.page';
import PowerBI from '../reports/PowerBI.page';
import Stickers from '../stickers/Stickers.page';

const AuthenticatedRoutes = ({
  userPermissions,
  adminPanelActivePermissions,
}) => (
  <Routes>
    <Route exact path="/" element={<Home />} />
    <Route
      exact
      path="/users"
      element={
        <AccessControl
          userPermissions={userPermissions}
          requiredPermission={adminPanelActivePermissions.find(
            (permission) => permission.title === 'Manage Users'
          )}
        >
          <Users />
        </AccessControl>
      }
    />
    <Route
      exact
      path="/stores"
      element={
        <AccessControl
          userPermissions={userPermissions}
          requiredPermission={adminPanelActivePermissions.find(
            (permission) => permission.title === 'Manage Stores'
          )}
        >
          <Stores />
        </AccessControl>
      }
    />
    <Route
      exact
      path="/stores/:store_id"
      element={
        <AccessControl
          userPermissions={userPermissions}
          requiredPermission={adminPanelActivePermissions.find(
            (permission) => permission.title === 'Manage Stores'
          )}
        >
          <StoreDetails />
        </AccessControl>
      }
    />
    <Route
      exact
      path="/users/:platform_id"
      element={
        <AccessControl
          userPermissions={userPermissions}
          requiredPermission={adminPanelActivePermissions.find(
            (permission) => permission.title === 'Manage Users'
          )}
        >
          <User />
        </AccessControl>
      }
    />
    <Route
      path="/priorities"
      element={
        <AccessControl
          userPermissions={userPermissions}
          requiredPermission={adminPanelActivePermissions.find(
            (permission) => permission.title === 'Manage Priorities'
          )}
        >
          <Priorities />
        </AccessControl>
      }
    />
    <Route
      path="/potd"
      element={
        <AccessControl
          userPermissions={userPermissions}
          requiredPermission={adminPanelActivePermissions.find(
            (permission) => permission.title === 'Manage Post of the Day'
          )}
        >
          <POTD />
        </AccessControl>
      }
    />
    <Route
      exact
      path="/potd/candidates"
      element={
        <AccessControl
          userPermissions={userPermissions}
          requiredPermission={adminPanelActivePermissions.find(
            (permission) => permission.title === 'Manage Post of the Day'
          )}
        >
          <POTDCandidates />
        </AccessControl>
      }
    />
    <Route
      exact
      path="/potd/schedule"
      element={
        <AccessControl
          userPermissions={userPermissions}
          requiredPermission={adminPanelActivePermissions.find(
            (permission) => permission.title === 'Manage Post of the Day'
          )}
        >
          <POTDCreate />
        </AccessControl>
      }
    />
    <Route
      exact
      path="/potd/update"
      element={
        <AccessControl
          userPermissions={userPermissions}
          requiredPermission={adminPanelActivePermissions.find(
            (permission) => permission.title === 'Manage Post of the Day'
          )}
        >
          <POTDEdit />
        </AccessControl>
      }
    />
    <Route
      exact
      path="/videoReviewDashboard"
      element={
        <AccessControl
          userPermissions={userPermissions}
          requiredPermission={adminPanelActivePermissions.find(
            (permission) => permission.title === 'Manage Decision'
          )}
        >
          <VideoReviewDashboard />
        </AccessControl>
      }
    />
    <Route
      exact
      path="/powerbi"
      element={
        <AccessControl
          userPermissions={userPermissions}
          requiredPermission={adminPanelActivePermissions.find(
            (permission) => permission.title === 'View Video Report'
          )}
        >
          <PowerBI />
        </AccessControl>
      }
    />
    <Route
      exact
      path="/stickers"
      element={
        <AccessControl
          userPermissions={userPermissions}
          requiredPermission={adminPanelActivePermissions.find(
            (permission) => permission.title === 'Manage Stickers'
          )}
        >
          <Stickers />
        </AccessControl>
      }
    />
  </Routes>
);

AuthenticatedRoutes.propTypes = {
  userPermissions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      active: PropTypes.bool.isRequired,
      title: PropTypes.string.isRequired,
      description: PropTypes.string,
      createdAt: PropTypes.string.isRequired,
      updatedAt: PropTypes.string.isRequired,
    })
  ).isRequired,
  adminPanelActivePermissions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      active: PropTypes.bool.isRequired,
      title: PropTypes.string.isRequired,
      description: PropTypes.string,
      createdAt: PropTypes.string.isRequired,
      updatedAt: PropTypes.string.isRequired,
    })
  ).isRequired,
};

const UnauthenticatedRoutes = () => (
  <Routes>
    <Route path="/*" element={<LoginPage />} />
    <Route path="/unauthorized" element={<Unauthorized />} />
  </Routes>
);

export default function Layout() {
  const { state } = useContext(UserContext);

  const routes = useMemo(() => {
    if (state.authenticated && !state.userIsLoading) {
      return (
        <AuthenticatedRoutes
          userPermissions={state.userPermissions}
          adminPanelActivePermissions={state.adminPanelActivePermissions}
        />
      );
    }
    if (!state.authenticated && !state.userIsLoading) {
      return <UnauthenticatedRoutes />;
    }
    return (
      <Grid container sx={{ justifyContent: 'center' }}>
        <Grid>
          <SpinningSpark height="50" />
        </Grid>
      </Grid>
    );
  }, [state]);

  return (
    <Box sx={{ display: 'flex' }}>
      {state.authenticated && !state.userIsLoading && <DrawerMenu />}
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Container maxWidth={false}>{routes}</Container>
      </Box>
    </Box>
  );
}
