import moment from 'moment-timezone';
import _ from 'lodash';

/**
 * Checks if a post is expired
 * @param {datetime} publish_time
 * @returns - true if post is expired, false otherwise
 */
export const isPostExpired = (publish_time) => {
  return moment(publish_time) < Date.now();
};

/**
 * Function broken out only for unit testing purposes
 * @param {string} timezone - user's timezone e.g. 'US/Eastern'
 * @returns current date and time in the user's timezone
 */
export const getNowinTimeZone = (timezone) => {
  return moment().tz(timezone).format('YYYY-MM-DD HH:mm');
};

/**
 * Function to format posts to user's timezone and sort by publish time
 * sorts so that the closest to publishing time is on top for unreviewed page
 * @param {Array} postList - list of posts
 * @param {string} timezone - user's timezone e.g. 'US/Eastern'
 * @returns sorted array with formatted publish time in user's timezone
 */
export const formatToUserTimezoneAndSortPosts = (postList, timezone) => {
  const formattedPosts = postList.map((post) => {
    // add a formatted publish time in the admin users's timezone
    return {
      ...post,
      tz_publish_time: moment
        .tz(post.publish_time, timezone)
        .format('YYYY-MM-DD HH:mm'),
    };
  });
  // sort by publish time so closest to publish_time is on top, and future dates are listed lower
  return _.sortBy(formattedPosts, 'tz_publish_time');
};

/**
 * Filters posts to only show posts whose publish_time is after now
 * unifies dates to user's timezone for display
 * sorts posts by publish time so closest to publish_time is on top
 * @param {Array} postList  - array of post objects
 * @param {*} timezone
 * @param {*} getNow - function to get current date and time in the user's timezone
 *                - used for unit testing; default is getNowinTimeZone, but unit test passes mock function
 * @returns
 */
export const filterPosts = (postList, timezone, getNow = getNowinTimeZone) => {
  const formattedPosts = formatToUserTimezoneAndSortPosts(postList, timezone);

  // only display posts whose publish time is after now
  // TODO: New tab will display history of expired, approved, blocked
  return formattedPosts.filter((post) => {
    return moment(post.tz_publish_time) > moment(getNow(timezone));
  });
};

export const getPostStatus = (post) => {
  const isExpired = isPostExpired(post.publish_time);
  if (isExpired && post.approved === null) {
    return 'expired';
  }
  if (post.reviewAction) {
    return post.reviewAction.action;
  }
  if (post.approved === true || post.approved === 'true') {
    return 'approved';
  }
  if (post.approved === false || post.approved === 'false') {
    return 'rejected';
  }

  return 'pending';
};

export const formattedFirstname = (firstname, lastname = '') => {
  if (!firstname) return null;
  const splittedFirstname = firstname.split(' ');
  const remainingParts = splittedFirstname.slice(1, splittedFirstname.length);
  return `${splittedFirstname[0].replace(
    /^(\w).*/,
    '$1.'
  )} ${remainingParts.join(' ')} ${lastname}`;
};
