import * as React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  Icon,
  CardContent,
  Typography,
  Stack,
  Tooltip,
  Button,
  Collapse,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import InfoIcon from '@mui/icons-material/Info';
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import InstagramIcon from '@mui/icons-material/Instagram';
import ReactPlayer from 'react-player';
import moment from 'moment-timezone';
import StatusHeader from './statusHeader';
import AudioDetectedCard from './audioDetectedCard';
import colors from '../colors.styles';
import { formattedFirstname } from './videoReview.service';

const actionMap = {
  approve: 'approve',
  reject: 'reject',
};

export default function VideoReviewCard(props) {
  const { post, allowActions, approvePost, rejectPost } = props;
  const [action, setAction] = React.useState('');
  const [expandConfirmationButton, setExpandConfirmationButton] =
    React.useState(false);
  const [confirmActionLoading, setConfirmActionLoading] = React.useState(false);

  const date = moment(post.tz_publish_time).format('MMMM Do, YYYY');
  const time = moment(post.tz_publish_time)
    .format('h:mmA')
    .concat(` ${moment.tz(moment.tz.guess()).zoneAbbr()}`);

  const user_id = Object.entries(post.created_by_user_id).find(([key]) =>
    key.endsWith('_walmart_id')
  )[1];
  const formattedNameWithId = `${formattedFirstname(
    post.created_by_firstname,
    post.created_by_lastname
  )} (${user_id})`;
  // Status to control if the video is playing
  const [isPlaying, setIsPlaying] = React.useState(false);
  const handlePlayVideo = () => {
    setIsPlaying(true);
  };
  function generateChannelIcons(channels) {
    return channels.map((channel) => {
      if (channel === 'facebook') {
        return (
          <Icon key={channel}>
            <FacebookRoundedIcon sx={{ color: colors.social.facebook }} />
          </Icon>
        );
      }
      if (channel === 'instagram') {
        return (
          <Icon key={channel}>
            <InstagramIcon sx={{ color: colors.social.instagram }} />
          </Icon>
        );
      }
      return null;
    });
  }

  function confirmAction() {
    setConfirmActionLoading(true);
    if (action === actionMap.approve) {
      approvePost(post.id, post.publish_time, setExpandConfirmationButton);
    }

    if (action === actionMap.reject) {
      rejectPost(post.id, post.publish_time, setExpandConfirmationButton);
    }
  }

  const styles = {
    card: {
      alignSelf: 'center',
      width: '100%',
      display: 'flex',
      paddingRight: '1vw',
    },
    actionBox: {
      display: 'flex',
      alignItems: 'right',
      pl: 1,
      pb: 1,
      justifyContent: 'center',
    },
    button: (selected) => ({
      borderRadius: '50px',
      backgroundColor: selected ? '#041F41' : colors.base.very_light_blue,
      color: selected ? '#fff' : '#000',
      '&:hover': { color: '#fff' },
    }),
    confirmButtonContainer: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: '10px',
      marginBottom: '10px',
    },
    confirmButton: {
      color: colors.base.walmart_blue,
      fontWeight: '600',
      textTransform: 'capitalize',
    },
    reactPlayer: {
      backgroundColor: '#000',
      width: '50%',
      maxWidth: '35vw',
      minWidth: '20vw',
      margin: 'auto 0',
      pointerEvents: isPlaying ? 'auto' : 'none',
    },
    postContent: {
      flex: '1 0 auto',
    },
    cardContentWrapper: {
      display: 'flex',
      flexDirection: 'column',
      ml: '30px',
      width: '50%',
    },
  };

  return (
    <Card sx={styles.card}>
      <Box
        sx={{
          backgroundColor: '#000',
          display: 'flex',
          cursor: 'pointer',
        }}
        onClick={handlePlayVideo}
      >
        <ReactPlayer
          url={post.video_url}
          controls
          playing={isPlaying}
          light={!isPlaying && <img src={post.thumbnail} alt="Thumbnail" />}
          style={styles.reactPlayer}
        />
      </Box>
      <Box sx={styles.cardContentWrapper}>
        <CardContent sx={styles.postContent}>
          <StatusHeader post={post} />
          <Stack direction="row" spacing={1}>
            {generateChannelIcons(post.channels)}
            <Typography variant="subtitle1" component="div">
              <span style={{ fontWeight: '600' }}>{formattedNameWithId} </span>{' '}
              from{' '}
              <span style={{ fontWeight: '600' }}>
                Store {post.store_number}
              </span>
            </Typography>
            {post.has_user_been_rejected && (
              <Tooltip
                title="User has posts that have been rejected before."
                placement="top"
                arrow
                sx={{ color: colors.base.spark_yellow }}
              >
                <Icon>
                  <InfoIcon />
                </Icon>
              </Tooltip>
            )}
          </Stack>
          <Typography variant="subtitle2" component="div" sx={styles.subtitle}>
            Scheduled for {date} at {time}
          </Typography>
          <Typography variant="body1" component="div" sx={{ pt: '20px' }}>
            {post.message}
          </Typography>
          <AudioDetectedCard audios={post.audio_detected} />
        </CardContent>
        {allowActions && (
          <>
            <Box sx={styles.actionBox}>
              <Stack direction="row" spacing={2}>
                <Button
                  variant="contained"
                  size="large"
                  sx={styles.button(action === actionMap.approve)}
                  onClick={() => {
                    setExpandConfirmationButton(true);
                    setAction(actionMap.approve);
                  }}
                  disabled={confirmActionLoading}
                >
                  Approve
                </Button>
                <Button
                  variant="contained"
                  size="large"
                  sx={styles.button(action === actionMap.reject)}
                  onClick={() => {
                    setExpandConfirmationButton(true);
                    setAction(actionMap.reject);
                  }}
                  disabled={confirmActionLoading}
                >
                  Reject
                </Button>
              </Stack>
            </Box>
            <Collapse in={expandConfirmationButton} timeout="auto">
              <Box sx={styles.confirmButtonContainer}>
                <LoadingButton
                  variant="text"
                  loading={confirmActionLoading}
                  sx={styles.confirmButton}
                  onClick={() => confirmAction()}
                >
                  Confirm Review
                </LoadingButton>
              </Box>
            </Collapse>
          </>
        )}
      </Box>
    </Card>
  );
}

VideoReviewCard.propTypes = {
  post: PropTypes.shape({
    id: PropTypes.string,
    channels: PropTypes.arrayOf(PropTypes.string),
    store_number: PropTypes.string,
    message: PropTypes.string,
    created_by_firstname: PropTypes.string,
    created_by_lastname: PropTypes.string,
    created_by_user_id: PropTypes.shape({}),
    email: PropTypes.string,
    video_url: PropTypes.string,
    thumbnail: PropTypes.string,
    publish_time: PropTypes.string,
    tz_publish_time: PropTypes.string,
    timezone: PropTypes.string,
    audio_detected: PropTypes.arrayOf(
      PropTypes.shape({
        isrc: PropTypes.string,
        title: PropTypes.string,
        artist: PropTypes.string,
        label: PropTypes.string,
        distributor: PropTypes.string,
        allowedMusic: PropTypes.bool,
        audioMatchStart: PropTypes.number,
        audioMatchEnd: PropTypes.number,
      })
    ),
    has_user_been_rejected: PropTypes.bool,
    reviewAction: PropTypes.shape({
      reviewed_by_walmart_id: PropTypes.string,
      reviewed_at: PropTypes.string,
      reviewed_by: PropTypes.string,
      action: PropTypes.string,
    }),
  }).isRequired,
  allowActions: PropTypes.bool,
  approvePost: PropTypes.func.isRequired,
  rejectPost: PropTypes.func.isRequired,
};

VideoReviewCard.defaultProps = {
  allowActions: false,
};
