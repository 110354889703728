import AppApi from '../shared/App.api';

export default {
  getVideosToReview,
  getReviewedVideos,
  approvePost,
  rejectPost,
};

const adminBaseUrl = '/admin';

function getVideosToReview() {
  return AppApi.get(`${adminBaseUrl}/review`);
}

function getReviewedVideos() {
  return AppApi.get(`${adminBaseUrl}/reviewed`);
}

function approvePost(postId, body) {
  return AppApi.postJson(`${adminBaseUrl}/review/${postId}/approve`, body);
}

function rejectPost(postId, body) {
  return AppApi.postJson(`${adminBaseUrl}/review/${postId}/reject`, body);
}
