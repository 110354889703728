import * as React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Icon,
  Typography,
  ThemeProvider,
  createTheme,
  Tooltip,
} from '@mui/material';
import { CheckCircle, Cancel } from '@mui/icons-material';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import colors from '../colors.styles';

const theme = createTheme({
  palette: {
    color: {
      error: '#ED3B3B',
      success: '#10B552',
    },
  },
  typography: {
    fontFamily: 'sans-serif',
    subtitle1: {
      fontSize: 14,
      fontWeight: 500,
      marginRight: 3,
    },
    subtitle2: {
      fontSize: 14,
      color: colors.base.medium_gray,
      fontWeight: 400,
      marginRight: 3,
    },
    body2: {
      fontSize: 12,
      color: colors.base.medium_gray,
      fontWeight: 400,
      marginLeft: 6,
      marginTop: 1,
    },
    h2: {
      fontSize: 12,
      color: '#5D6A7D',
      fontWeight: 400,
    },
  },
});

export default function AudioDetectedCard(props) {
  const { audios } = props;

  const distributorPriority = {
    'sony music entertainment': 1,
    'warner music group': 2,
    'universal music group': 3,
  };

  const getDistributorPriority = (distributor) =>
    distributorPriority[distributor.toLowerCase()] || 4;

  let sortedAudios = [];
  if (audios) {
    sortedAudios = [...audios].sort((a, b) => {
      return a.distributor && b.distributor
        ? getDistributorPriority(a.distributor) -
            getDistributorPriority(b.distributor)
        : 0;
    });
  }

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, '0')}:${remainingSeconds
      .toString()
      .padStart(2, '0')}`;
  };

  return (
    <ThemeProvider theme={theme}>
      <Typography variant="h2" component="div" sx={{ marginTop: '20px' }}>
        Audio detected:
      </Typography>
      {sortedAudios.length > 0 ? (
        sortedAudios.map((audio) => (
          <Box
            key={`${audio.title}-${audio.artist}`}
            display="flex"
            alignItems="top"
            my={1}
          >
            {!audio.allowedMusic ? (
              <Tooltip
                title="This audio is not permitted for use."
                placement="bottom"
                arrow
              >
                <Cancel
                  fontSize="small"
                  sx={{
                    color: 'color.error',
                  }}
                />
              </Tooltip>
            ) : (
              <Tooltip
                title="Great! This audio is allowed for MLS users."
                placement="top"
                arrow
              >
                <CheckCircle
                  fontSize="small"
                  sx={{
                    color: 'color.success',
                  }}
                />
              </Tooltip>
            )}
            <Box ml={1}>
              <Box display="flex" alignItems="center">
                <Box display="flex" alignItems="center">
                  <Typography variant="subtitle1">
                    <strong>{audio.title}</strong>
                  </Typography>
                  <Typography variant="subtitle2">by</Typography>
                  <Typography variant="subtitle1">
                    <strong>{audio.artist}</strong>
                  </Typography>
                </Box>
                <Typography variant="body2" component="span">
                  {formatTime(audio.audioMatchStart)} -{' '}
                  {formatTime(audio.audioMatchEnd)}
                </Typography>
              </Box>
              {!audio.allowedMusic && (
                <>
                  <Box display="flex" alignItems="center">
                    <Typography variant="subtitle2">Label:</Typography>
                    <Typography variant="subtitle1">
                      <strong>{audio.label}</strong>
                    </Typography>
                  </Box>
                  <Box display="flex" alignItems="center">
                    <Typography variant="subtitle2">Distributor:</Typography>
                    {audio.distributor === 'Sony Music Entertainment' && (
                      <Icon color="error" sx={{ marginBottom: '0.5vh' }}>
                        <WarningAmberIcon />
                      </Icon>
                    )}
                    <Typography variant="subtitle1">
                      <strong>{audio.distributor}</strong>
                    </Typography>
                  </Box>
                </>
              )}
            </Box>
          </Box>
        ))
      ) : (
        <Typography variant="subtitle1" sx={{ marginTop: '10px' }}>
          No audio matches found.
        </Typography>
      )}
    </ThemeProvider>
  );
}

AudioDetectedCard.propTypes = {
  audios: PropTypes.arrayOf(
    PropTypes.shape({
      isrc: PropTypes.string,
      title: PropTypes.string,
      artist: PropTypes.string,
      label: PropTypes.string,
      distributor: PropTypes.string,
      allowedMusic: PropTypes.bool,
      audioMatchStart: PropTypes.number,
      audioMatchEnd: PropTypes.number,
    })
  ).isRequired,
};
